<template>
  <section>
    <b-row class="mt-1">
      <b-col class="text-right">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          :to="{ name: 'user-create' }"
        >
          <feather-icon icon="PlusIcon" class="mr-50" />
          <span>Créer Utilisateur</span>
        </b-button>
      </b-col>
    </b-row>
    <div class="mt-2">
      <user-list-array-view />
    </div>
  </section>
</template>

<script>
import { PageBoxed } from "@/views/utils/PageBoxed.js";
import { BRow, BCol, BButton } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import UserListArrayView from "@/views/users/user-list/UserListArrayView.vue";

export default {
  components: {
    BRow,
    BCol,
    BButton,

    UserListArrayView,
  },
  mixins: [PageBoxed],
  directives: {
    Ripple,
  },
};
</script>
