<template>
  <!-- Table Container Card -->
  <b-card no-body>
    <b-table
      ref="refUserListTable"
      :items="fetchUsers"
      responsive
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      empty-text="No matching records found"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
    >
      <!-- Column: Client -->
      <template #cell(utilisateur)="data">
        <b-media vertical-align="center">
          <template #aside>
            <b-avatar
              size="32"
              :src="
                resolveAvatarLink(data.item.first_name, data.item.last_name)
              "
              :text="avatarText(data.item.first_name)"
              :to="{ name: 'user-detail', params: { id: data.item.ID } }"
            />
          </template>
          <b-link
            :to="{ name: 'user-detail', params: { id: data.item.ID } }"
            class="font-weight-bold d-block text-nowrap"
          >
            {{ data.item.first_name }} {{ data.item.last_name }}
          </b-link>
          <small class="text-muted">{{ data.item.email }}</small>
        </b-media>
      </template>

      <!-- Column: Entreprise -->
      <template #cell(entreprise)="data">
        <span class="text-nowrap">
          {{ data.item.company.name }}
        </span>
      </template>

      <!-- Column: Emploi -->
      <template #cell(emploi)="data">
        <span class="text-nowrap">
          {{ data.item.job_role }}
        </span>
      </template>

      <!-- Column: Status -->
      <template #cell(statut)="data">
        <b-badge
          pill
          :variant="`light-${resolveStatusVariant(data.item.status)}`"
        >
          {{ data.item.status }}
        </b-badge>
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted"
            >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
            {{ dataMeta.of }} entries</span
          >
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalUsers"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BPagination,
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import { resolveAvatarLink } from "@core/utils/utils";
import { onUnmounted } from "@vue/composition-api";
import store from "@/store";
import useUsersList from "./useUserList";

import userStoreModule from "../userStoreModule";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BPagination,
  },
  props: {
    filteredCompanyId: {
      type: String,
      required: false,
    },
  },
  setup(props) {
    const USER_APP_STORE_MODULE_NAME = "app-user";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    const {
      fetchUsers,
      tableColumns,
      filterCompanyId,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      sortBy,
      isSortDirDesc,
      refUserListTable,

      refetchData,

      resolveStatusVariant,
    } = useUsersList();

    filterCompanyId.value = props.filteredCompanyId;

    return {
      fetchUsers,
      tableColumns,
      filterCompanyId,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      sortBy,
      isSortDirDesc,
      refUserListTable,

      refetchData,

      avatarText,
      resolveStatusVariant,
      resolveAvatarLink,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.per-page-selector {
  width: 90px;
}

.user-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>
