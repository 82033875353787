import Vue from "vue";
import * as Api from "../../axios-generated/backoffice/";
// @ts-ignore
import axios from "@axios";

const USER_API = new Api.UsersApi(
  undefined,
  Vue.prototype.$config.VUE_APP_BACKOFFICE_API_BASE_URL,
  axios
);

const COMPANIES_API = new Api.CompaniesApi(
  undefined,
  Vue.prototype.$config.VUE_APP_BACKOFFICE_API_BASE_URL,
  axios
);

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchCustomers() {
      return new Promise((resolve, reject) => {
        COMPANIES_API.findCompanies()
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchUsersByCompanyID(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        COMPANIES_API.findUsersByCompanyID(
          queryParams.filterCompanyId,
          queryParams.sortBy,
          queryParams.perPage,
          queryParams.page,
          queryParams.sortDesc
        )
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchCompanyByID(ctx, { companyID }) {
      return new Promise((resolve, reject) => {
        COMPANIES_API.findCompanyByID(companyID)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    editPartialCompany(ctx, { editPartialCompany }) {
      return new Promise((resolve, reject) => {
        COMPANIES_API.updatePartialCompany(
          editPartialCompany.ID,
          editPartialCompany
        )
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        USER_API.findUsers(
          queryParams.sortBy,
          queryParams.perPage,
          queryParams.page,
          queryParams.sortDesc
        )
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchUserByID(ctx, { userID }) {
      return new Promise((resolve, reject) => {
        USER_API.getUser(userID)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    createUser(ctx, { newUser }) {
      return new Promise((resolve, reject) => {
        USER_API.createUser(newUser)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    editUser(ctx, { editUser }) {
      return new Promise((resolve, reject) => {
        USER_API.updateUser(editUser.ID, editUser)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
