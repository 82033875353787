import { ref, watch, computed } from "@vue/composition-api";
// @ts-ignore
import store from "@/store";

import * as Api from "../../../axios-generated/backoffice/";

// Notification
import { useToast } from "vue-toastification/composition";
// @ts-ignore
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function useUsersList() {
  // Use toast
  const toast = useToast();

  const refUserListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: "utilisateur", sortable: true },
    { key: "entreprise", sortable: true },
    { key: "emploi", sortable: true },
    { key: "role", sortable: true },
    { key: "statut", sortable: true },
  ];
  const filterCompanyId = ref("");
  const perPage = ref(10);
  const totalUsers = ref(0);
  const currentPage = ref(1);
  const sortBy = ref("uuid");
  const isSortDirDesc = ref(true);

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value
      ? refUserListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    };
  });

  const refetchData = () => {
    refUserListTable.value.refresh();
  };

  const filterSortBy = (sortByParam) => {
    switch (sortByParam) {
      case "utilisateur":
        return "first_name";
      case "entreprise":
        return "company_uuid";
      case "emploi":
        return "job_role";
      case "role":
        return "role";
      case "statut":
        return "status";
      default:
        return "uuid";
    }
  };

  watch([currentPage, perPage], () => {
    refetchData();
  });

  const fetchUsers = (ctx, callback) => {
    const sortByFiltered = filterSortBy(sortBy.value);
    if (filterCompanyId.value !== undefined) {
      store
        .dispatch("app-user/fetchUsersByCompanyID", {
          filterCompanyId: filterCompanyId.value,
          perPage: perPage.value,
          page: currentPage.value,
          sortBy: sortByFiltered,
          sortDesc: isSortDirDesc.value,
        })
        .then((response) => {
          const users = response.data.users;
          totalUsers.value = response.data.count;
          callback(users);
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: "Error fetching users' list",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    } else {
      store
        .dispatch("app-user/fetchUsers", {
          perPage: perPage.value,
          page: currentPage.value,
          sortBy: sortByFiltered,
          sortDesc: isSortDirDesc.value,
        })
        .then((response) => {
          const users = response.data.users;
          totalUsers.value = response.data.count;
          callback(users);
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: "Error fetching users' list",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    }
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveStatusVariant = (status) => {
    if (status === Api.UserStatusEnum.Disabled) return "danger";
    if (status === Api.UserStatusEnum.Created) return "info";
    if (status === Api.UserStatusEnum.Enabled) return "success";
    return "primary";
  };

  return {
    fetchUsers,
    tableColumns,
    filterCompanyId,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    sortBy,
    isSortDirDesc,
    refUserListTable,

    resolveStatusVariant,

    refetchData,
  };
}
